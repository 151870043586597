// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon,
} from "@material-ui/icons";

import AdjustIcon from "@material-ui/icons/Adjust";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "forgot",
    path: "/forgot",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "change-password",
    path: "/changepassword",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "security",
    path: "/securityType",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  /* Dashboard */
  {
    id: "dashboard",
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    isSideMenu: true,
    isEdit: false,
    isRestricted: "single",
  },

  /*Admin  */
  // {
  //   id: "admin",
  //   path: "/list",
  //   name: "Admin Management",
  //   icon: List,
  //   isSideMenu: true,
  //   isEdit: true,
  //   isRestricted: "multi",
  //   child: [
  //     {
  //       id: "add",
  //       path: "/add",
  //     },
  //     {
  //       id: "edit",
  //       path: "/edit/:userId",
  //     },
  //   ],
  // },

  /* Dashboard */
  // {
  //   path: "#",
  //   id: "dashboard",
  //   name: "Dashboard",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Dashboard Lists",
  //       path: "/dashboard",
  //       icon: Person,
  //     },
  //   ],
  // },

  /* User */

  // {
  //   path: "#",
  //   id: "category",
  //   name: "Category Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Category Lists",
  //       path: "/categorylist",
  //       icon: Person,
  //     },
  //   ],
  // },

  /* User */

  {
    path: "#",
    id: "currency",
    name: "Currency",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Currency List",
        path: "/currency",
        icon: List,
      },
    ],
  },

  {
    path: "#",
    id: "cms",
    name: "CMS Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "CMS List",
        path: "/CmsList",
        icon: AdjustIcon,
      },
    ],
  },

  {
    path: "#",
    id: "DAPPS",
    name: "DAPPS ",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "DAPPS List",
        path: "/DAPPSlist",
        icon: Person,
      },
      {
        name: "Category List",
        path: "/categorylist",
        icon: Person,
      },
    ],
  },

  {
    path: "#",
    id: "token",
    name: "Token Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Token List",
        path: "/TokenList",
        icon: Person,
      },
    ],
  },

  {
    path: "#",
    id: "Price",
    name: "Price Conversion",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Price Conversion",
        path: "/PriceCnvList",
        icon: AdjustIcon,
      },
    ],
  },

  {
    path: "#",
    id: "Network",
    name: "Network",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Network List",
        path: "/network",
        icon: List,
      },
    ],
  },
  {
    path: "#",
    id: "explore",
    name: "Explore Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Explore List",
        path: "/explore",
        icon: List,
      },
    ],
  },
  {
    path: "#",
    id: "fiatcurrency",
    name: "Fiat Currency",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Fiat Currency",
        path: "/fiatcurrency",
        icon: List,
      },
    ],
  },
  {
    path: "#",
    id: "support",
    name: "Support Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Support List",
        path: "/supportlist",
        icon: List,
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    icon: ExitToAppIcon,
    isSideMenu: true,
  },

  // {
  //   path: "#",
  //   id: "token",
  //   name: "Token Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Token Lists",
  //       path: "/TokenList",
  //       icon: Person,
  //     },

  //   ]
  // },
  // {
  //   path: "#",
  //   id: "request",
  //   name: "Verification",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Email Verification",
  //       path: "/verificationlist",
  //       icon: Person,
  //     },

  //   ]
  // },

  // {
  //   path: "#",
  //   id: "Setting",
  //   name: "Setting Details",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Setting",
  //       path: "/setting",
  //       icon: Person,
  //     }

  //   ]
  // },
  // {
  //   path: "#",
  //   id: "Community category",
  //   name: "Community Category",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Category Lists",
  //       path: "/CommunitycategoryList",
  //       icon: AdjustIcon,
  //     },
  //     {
  //       name: "Category Users",
  //       path: "/CommunityUserList",
  //       icon: AdjustIcon,
  //     }
  //   ]
  // },
  // {
  //   path: "#",
  //   id: "Faq",
  //   name: "Faq",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "FaqList",
  //       path: "/Faq",
  //       icon: Person,
  //     }
  //   ]
  // },

  // {
  //   path: "#",
  //   id: "emailtemplate",
  //   name: "Email Template",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Email Template List",
  //       path: "/emailTemplate",
  //       icon: Person,
  //     },
  //   ],
  // },
  // {
  //   path: "#",
  //   id: "Community",
  //   name: "Community",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Community Category",
  //       path: "/Communitycategorylist",
  //       icon: Person,
  //     }
  //   ]
  // },
  //  {
  //     path: "#",
  //     id: "cms",
  //     name: "CMS",
  //     icon: List,
  //     type: "multi",
  //     isEdit: false,
  //     isSideMenu: true,
  //     child: [
  //       {
  //         name: "CMS",
  //         path: "/cms",
  //         icon: Person,
  //       },
  //       {
  //         name: "CMS Block",
  //         path: "/cmsblock",
  //         icon: Person,
  //       }
  //     ]
  //   },
  // {
  //   path: "#",
  //   id: "faq",
  //   name: "FAQ",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "FaqList",
  //       path: "/faq",
  //       icon: Person,
  //     }
  //   ]
  // },
  // {
  //   path: "#",
  //   id: "Prohibited",
  //   name: "Prohibited",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Prohibited",
  //       path: "/prohibited",
  //       icon: Person,
  //     }
  //   ]
  // },

  // {
  //   path: "#",
  //   id: "user",
  //   name: "User Management",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "User Lists",
  //       path: "/userList",
  //       icon: Person,
  //     },
  //   ],
  // },

  //   {
  //     path: "/idproof",
  //     id: "proof",
  //     path: "#",
  //     name: "Doc Verfication",
  //     icon: Person,
  //     type: "multi",
  //     isSideMenu: true,
  //     child: [
  //       {
  //         path: "/idproof",
  //         name: "ID Proof",
  //         icon: Person,
  //       },
  //       {
  //         path: "/selfieproof",
  //         name: "Selfie Proof",
  //         icon: Person,
  //       },
  //       {
  //         path: "/addressproof",
  //         name: "Address Proof",
  //         icon: Person,
  //       },
  //       // {
  //       //   path: "/bankproof",
  //       //   name: "BanK Proof",
  //       //   icon: Person,
  //       // },

  //     ]
  //   },
  //   {
  //     path: "/currency",
  //     name: "Currency",
  //     icon: Money,
  //     isSideMenu: true,
  //   },
  //   {
  //     path: "/tradePairs",
  //     name: "Trade Pairs",
  //     icon: LibraryBooks,
  //     isSideMenu: true,
  //   },
  //   {
  //     path: "/report",
  //     id: "report",
  //     path: "#",
  //     name: "Report",
  //     icon: Equalizer,
  //     type: "multi",
  //     isSideMenu: true,
  //     child: [
  //       {
  //         path: "/trade-history",
  //         name: "Trade",
  //         icon: History,
  //       },
  //       {
  //         path: "/withdraw",
  //         name: "Withdraw",
  //         icon: AccountBalanceWallet,
  //       },
  //       {
  //         path: "/fund-transfer-history",
  //         name: "Fund Transfer",
  //         icon: AccountBalanceWallet,
  //       }
  //     ]
  //   },
  //   {
  //     path: "/wallet",
  //     id: "wallet",
  //     path: "#",
  //     name: "Wallet",
  //     icon: AccountBalance,
  //     type: "multi",
  //     isSideMenu: true,
  //     child: [
  //       {
  //         path: "/withdraw-request/coin",
  //         name: "Coin Withdraw Request",
  //         icon: AccountBalanceWallet,
  //       },
  //       {
  //         path: "/withdraw-request/fiat",
  //         name: "Withdraw Request",
  //         icon: ExitToApp,
  //       },
  //       {
  //         path: "/deposit-request/fiat",
  //         name: "Deposit Request",
  //         icon: ExitToApp,
  //       },
  //     ]
  //   },
  //   {
  //     path: "/securityType",
  //     id: "setting",
  //     path: "#",
  //     name: "Settings",
  //     icon: Settings,
  //     type: "multi",
  //     isSideMenu: true,
  //     child: [
  //       {
  //         path: "/securityType",
  //         name: "Security Type",
  //         isRestricted: 'common',
  //         icon: Person,
  //       }
  //     ]
  //   },

  //   {
  //     path: "/",
  //     id: "referral",
  //     path: "#",
  //     name: "Referral",
  //     icon: Settings,
  //     type: "multi",
  //     isSideMenu: true,
  //     child: [
  //       {
  //         path: "/referral/fees",
  //         name: "Fees Setting",
  //         isRestricted: 'common',
  //         icon: Person,
  //       },
  //       {
  //         path: "/referral/history",
  //         name: "Referral History",
  //         isRestricted: 'common',
  //         icon: Person,
  //       },
  //     ]
  //   },

  //   {
  //     path: "/emailTemplate",
  //     name: "Email Template",
  //     icon: Email,
  //     isSideMenu: true,
  //   },
  //   {
  //     path: "/support",
  //     id: "support",
  //     path: "#",
  //     name: "Support",
  //     icon: Help,
  //     type: "multi",
  //     isSideMenu: true,
  //     child: [
  //       {
  //         path: "/support/category",
  //         name: "Support Category",
  //         icon: Help,
  //       },
  //       {
  //         path: "/support/ticket",
  //         name: "Support Ticket",
  //         icon: Help,
  //       },
  //     ]
  //   },
  //   {
  //     path: "/faq",
  //     name: "Faq",
  //     icon: LiveHelp,
  //     isSideMenu: true,
  //   }
  // }
];

export default dashboardRoutes;
