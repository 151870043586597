import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import action
import { addtoken } from "../../actions/users";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import fileObjectUrl from "../../lib/fileObjectUrl";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const initialFormValue = {
  type: "",
  currencyName: "",
  currencySymbol: "",
  withdrawFee: "",
  tokenType: "",
  decimals: "",
  minabi: "", // if token
  contractAddress: "", // if token
  bankName: "", // if fiat
  name: "", // if fiat
  accountNo: "", // if fiat
  routingNo: "", // if fiat
  currencyimage: "",
};

const useStyles = makeStyles(styles);

const AddToken = () => {
  const classes = useStyles();
  const history = useHistory();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const {
    type,
    tokenType,
    currencyName,
    currencySymbol,
    withdrawFee,
    minabi,
    decimals,
    contractAddress,
    bankName,
    name,
    accountNo,
    routingNo,
    currencyimage,
  } = formValue;

  // function

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const handleFile = (event) => {
    const { name, files } = event.target;
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currencyimage) {
        if (currencyimage.size > 150000) {
          setValidateError({
            errors: { currencyimage: "Image size should be less than  150 Kb" },
          });
          toastAlert(
            "error",
            "Image size should be less than  150 Kb",
            "currencyUpdateModal"
          );
          return false;
        }
      }
      let reqData = new FormData();
      reqData.append("type", type);
      reqData.append("currencyName", currencyName);
      reqData.append("currencySymbol", currencySymbol);
      reqData.append("withdrawFee", withdrawFee);
      reqData.append("decimals", decimals);
      reqData.append("contractAddress", contractAddress);
      reqData.append("tokenType", tokenType);
      reqData.append("currencyimage", currencyimage);

      // console.log(data,'data')

      const { status, loading, messages, error } = await addtoken(reqData);
      if (status == "success") {
        toastAlert("success", messages, "addtoken");
        setFormValue(initialFormValue);
        setValidateError({});
        history.push("/Tokenlist");
      } else {
        setValidateError(error);
      }
    } catch (err) {
      console.log(err, "errror");
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New Token</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    {/* <div className="custom_select">
                      <InputLabel id="demo-controlled-open-select-label">Currency Type</InputLabel>
                      <Select
                        className="d-block"
                        name="type"
                        value={type}
                        onChange={handleChange}
                      >
                       
                        <MenuItem value={"crypto"}>Crypto</MenuItem>
                        <MenuItem value={"token"}>Token</MenuItem>
                      </Select> */}
                    <CustomInput
                      labelText="Currency Type"
                      name="type"
                      value={type}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.type && (
                      <span className={classes.textDanger}>
                        {validateError.type}
                      </span>
                    )}
                    {/* </div> */}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Name"
                      name="currencyName"
                      value={currencyName}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.currencyName && (
                      <span className={classes.textDanger}>
                        {validateError.currencyName}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Symbol"
                      name="currencySymbol"
                      value={currencySymbol}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.currencySymbol && (
                      <span className={classes.textDanger}>
                        {validateError.currencySymbol}
                      </span>
                    )}
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            labelText="Withdraw Fee"
                                            onChange={handleChange}
                                            value={withdrawFee}
                                            name="withdrawFee"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                        {
                                            validateError.withdrawFee && <span className={classes.textDanger}>{validateError.withdrawFee}</span>
                                        }
                                    </GridItem> */}

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Token Type"
                      onChange={handleChange}
                      value={tokenType}
                      name="tokenType"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.tokenType && (
                      <span className={classes.textDanger}>
                        {validateError.tokenType}
                      </span>
                    )}
                    {/* </div> */}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Contract Address"
                      name="contractAddress"
                      value={contractAddress}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.contractAddress && (
                      <span className={classes.textDanger}>
                        {validateError.contractAddress}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Decimal"
                      name="decimals"
                      value={decimals}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.decimals && (
                      <span className={classes.textDanger}>
                        {validateError.decimals}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Image"
                      name="currencyimage"
                      onChange={handleFile}
                      type="file"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    <img
                      src={fileObjectUrl(currencyimage)}
                      width="50"
                      height="50"
                    />

                    {validateError.currencyimage && (
                      <span className={classes.textDanger}>
                        {validateError.currencyimage}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  ADD
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddToken;
