import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from "./components/Route/ConditionRoute";
import UsersList from "./pages/UsersList/UsersList";
import Usersedit from "./pages/UsersList/useredit";
import LoginHistory from "./pages/LoginHistory/loginHistory";

import EditProfile from "./pages/UserProfile/EditProfile";
import securityType from "./pages/Settings/securityType";
import EmailTemplate from "./pages/Emailtemplate/emailList";
import EmailTemplateUpdate from "./pages/Emailtemplate/updateTemplate";

import { AdminList, AdminAdd, AdminEdit } from "./pages/Admin";
import {
  CurrencyList,
  CurrencyAdd,
  CurrencyEdit,
} from "./pages/CurrencyManagement";

import {
  SupportCategoryList,
  SupportCategoryAdd,
  SupportCategoryEdit,
  TicketList,
  TicketChat,
} from "./pages/Support";
import { TradeHistory, WithdrawList, FundTansferHistory } from "./pages/Report";
import { SportPairList, SportPairAdd, SportPairEdit } from "./pages/TradePairs";
import {
  CoinWithdrawRequest,
  DepositRequest,
  WithdrawRequest,
} from "./pages/Wallet";
import { IdProof, SelfieProof, AddressProof } from "./pages/DocVerification";
import { FeeSetting, ReferralHistory } from "./pages/Referral";
import supportView from "./pages/Support/supportView";

import FaqAdd from "./pages/Faq/faqAdd";
import FaqList from "./pages/Faq/faqList";
import FaqUpdate from "./pages/Faq/faqUpdate";

// import pages
import LoginPage from "./pages/LoginPage/Login";
import widget from "./pages/spritzwidget";
import ForgotPage from "./pages/ForgotPage/ForgotPassword";
import changePassword from "./pages/ForgotPage/changePassword";
import forgotPassword from "./pages/ForgotPage/changeForgotpassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserProfile from "./pages/UserProfile/UserProfile";
import CategoryList from "./pages/Category/CategoryList";
import categoryEdit from "./pages/Category/categoryedit";
import categoryadd from "./pages/Category/categoryadd";
import logoutpage from "./pages/logout";
//Bid
import Bidpage from "./pages/Bids/Bids";

//Token
import TokenList from "./pages/Token/Tokenlist";
import TokenView from "./pages/Token/Tokenview";
import TokenAdd from "./pages/Token/Tokenadd";
import TokenUpdate from "./pages/Token/Tokenupdate";
// community
import CommunitycategoryList from "./community/Category/CategoryList";
import CommunitycategoryEdit from "./community/Category/categoryedit";
import Communitycategoryadd from "./community/Category/categoryadd";

import CommunityUserList from "./community/Category/CommunityUserList";

//priceconverion
import Pricecnv from "./pages/Priceconversion/Pricecnvlist";
import Pricecnvupdate from "./pages/Priceconversion/Pricecnvupdate";

//Exploremanagement
import Explore from "./pages/ExploreManagement/List";
import ExploreAdd from "./pages/ExploreManagement/Add";
import ExploreUpdate from "./pages/ExploreManagement/Update";
import SupportList from "./pages/SupportMangement/List";

// prohibited pages
import Prohibited from "./pages/Prohibited/prohibitedlist";
import ProhibitedAdd from "./pages/Prohibited/prohibitedadd";
import ProhibitedEdit from "./pages/Prohibited/prohibitededit";
//cms pagess
import CmsList from "./pages/Cms/Cmslist";
import Cmsauction from "./pages/Cms/Cmsauction";
import AddCMs from "./pages/Cms/Cmsadd";
import EditCMs from "./pages/Cms/cmsedit";

import Cms from "./pages/cmstermspolicy/cms";
import CmsEdit from "./pages/cmstermspolicy/cmsedit";
import Cmsblock from "./pages/cmsblock/cms";
import CmsblockEdit from "./pages/cmsblock/cmsedit";
// End community

import SettingList from "./pages/Settings/Setting";
import verificationlist from "./pages/request/verificationlist";
import store from "./store";
import Dapps from "./pages/Dapps/Dappslist";
import Dappsadd from "./pages/Dapps/Dappsadd";
import Dappsedit from "./pages/Dapps/Dappsedit";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

import Fiatlist from "./pages/FiatManagement/Fiatlist";
import Fiatadd from "./pages/FiatManagement/Fiatadd";
import Fiatedit from "./pages/FiatManagement/Fiatedit";
import globalStyle from "./style/global.style";

import NetworkList from "./pages/Network/list";

// import action
import { decodeJwt } from "./actions/jsonWebToken";

const history = createBrowserHistory();

const App = () => {
  const { isAuth } = store.getState().currentUser;

  useEffect(() => {
    if (isAuth != true && localStorage.admin_token) {
      decodeJwt(localStorage.admin_token, store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={globalStyle}>
        <ToastContainer />
        {/* <Router history={history} basename={process.env.REACT_APP_BASENAME || "/ulosdewvhref"}> */}
        <Router history={history} basename={"/"}>
          <Switch>
            {/* Auth Route */}
            <ConditionRoute path="/spritz" component={widget} />

            <ConditionRoute path="/login" component={LoginPage} type={"auth"} />
            <ConditionRoute path="/logout" component={logoutpage} />

            <ConditionRoute
              path="/forgot"
              component={ForgotPage}
              type={"auth"}
            />
            <ConditionRoute path="/changepassword" component={changePassword} />

            <ConditionRoute
              path="/forgotpassword"
              component={forgotPassword}
              type={"public"}
            />

            {/* Auth Route */}

            {/* Private Route */}
            <ConditionRoute
              path="/dashboard"
              component={Dashboard}
              layout={Admin}
              type={"private"}
              store={store}
            />

            {/* Admin */}
            <ConditionRoute
              exact
              path="/categorylist"
              component={CategoryList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/verificationlist"
              component={verificationlist}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/addcategory"
              component={categoryadd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/categoryedit/:userId"
              component={categoryEdit}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/list"
              component={AdminList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/add"
              component={AdminAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/eidt/:userId"
              component={AdminEdit}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/setting"
              component={SettingList}
              layout={Admin}
              type={"private"}
            />

            {/* Token  */}

            <ConditionRoute
              exact
              path="/TokenList"
              component={TokenList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/TokenAdd"
              component={TokenAdd}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/TokenUpdate/:id"
              component={TokenUpdate}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/TokenView/:userId"
              component={TokenView}
              layout={Admin}
              type={"private"}
            />

            {/* network */}
            <ConditionRoute
              exact
              path="/network"
              component={NetworkList}
              layout={Admin}
              type={"private"}
            />

            {/* priceconverison*/}
            <ConditionRoute
              exact
              path="/PriceCnvList"
              component={Pricecnv}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/PriceCnvUpdate/:id"
              component={Pricecnvupdate}
              layout={Admin}
              type={"private"}
            />

            {/* Dapps */}
            <ConditionRoute
              exact
              path="/DAPPSlist"
              component={Dapps}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/DAPPSadd"
              component={Dappsadd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/DAPPedit/:userId"
              component={Dappsedit}
              layout={Admin}
              type={"private"}
            />

            {/* Biddd */}
            <ConditionRoute
              exact
              path="/bidlist"
              component={Bidpage}
              layout={Admin}
              type={"private"}
            />

            {/* Cms */}

            <ConditionRoute
              exact
              path="/CmsList"
              component={CmsList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/Addcms"
              component={AddCMs}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/Editcms/:id"
              component={EditCMs}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/Cmsauction"
              component={Cmsauction}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/cmsblock"
              component={Cmsblock}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/cmsblockedit/:cmsId"
              component={CmsblockEdit}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/cms"
              component={Cms}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/cmsedit/:cmsId"
              component={CmsEdit}
              layout={Admin}
              type={"private"}
            />

            {/* explore */}
            <ConditionRoute
              path="/explore"
              component={Explore}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/addexplore"
              component={ExploreAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/updatexplore/:id"
              component={ExploreUpdate}
              layout={Admin}
              type={"private"}
            />

            {/*support */}
            <ConditionRoute
              path="/supportlist"
              component={SupportList}
              layout={Admin}
              type={"private"}
            />
            {/* Admin */}

            <ConditionRoute
              path="/user"
              component={UserProfile}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/userList"
              component={UsersList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/useredit/:userId"
              component={Usersedit}
              layout={Admin}
              type={"private"}
            />

            {/* Setting */}
            <ConditionRoute
              path="/securityType"
              component={securityType}
              layout={Admin}
              type={"private"}
            />
            {/* Setting */}

            {/* Referral */}
            <ConditionRoute
              path="/referral/fees"
              component={FeeSetting}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/referral/history"
              component={ReferralHistory}
              layout={Admin}
              type={"private"}
            />
            {/* Referral */}

            {/* <ConditionRoute path="/support" component={support} layout={Admin} type={"private"} /> */}
            <ConditionRoute
              path="/supportView/:id"
              component={supportView}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/currency"
              component={CurrencyList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/addCurrency"
              component={CurrencyAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/updateCurrency/:currencyId"
              component={CurrencyEdit}
              layout={Admin}
              type={"private"}
            />

            {/* Trade Pair */}
            <ConditionRoute
              path="/tradePairs"
              component={SportPairList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/addPairs"
              component={SportPairAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/updatePairs/:pairId"
              component={SportPairEdit}
              layout={Admin}
              type={"private"}
            />
            {/* Trade Pair */}
            {/* Prohibited  */}
            <ConditionRoute
              path="/prohibited"
              component={Prohibited}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/prohibitedadd"
              component={ProhibitedAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/prohibitededit/:wordId"
              component={ProhibitedEdit}
              layout={Admin}
              type={"private"}
            />
            {/* Prohibited */}

            {/* Wallet */}
            <ConditionRoute
              path="/withdraw-request/coin"
              component={CoinWithdrawRequest}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/deposit-request/fiat"
              component={DepositRequest}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/withdraw-request/fiat"
              component={WithdrawRequest}
              layout={Admin}
              type={"private"}
            />
            {/* Wallet */}

            <ConditionRoute
              path="/addFaq"
              component={FaqAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/Faq"
              component={FaqList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/faqUpdate/:faqId"
              component={FaqUpdate}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              path="/loginhistory"
              component={LoginHistory}
              layout={Admin}
              type={"private"}
            />

            {/* Document Verification */}
            <ConditionRoute
              path="/idproof"
              component={IdProof}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/addressproof"
              component={AddressProof}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/selfieproof"
              component={SelfieProof}
              layout={Admin}
              type={"private"}
            />
            {/* Document Verification */}

            <ConditionRoute
              path="/editprofile"
              component={EditProfile}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/emailTemplate"
              component={EmailTemplate}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/emailUpdatecms/:cmsId"
              component={EmailTemplateUpdate}
              layout={Admin}
              type={"private"}
            />

            {/* Private Route */}

            {/* Suppot Ticket */}
            <ConditionRoute
              exact
              path="/support/category"
              component={SupportCategoryList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/support/category/add"
              component={SupportCategoryAdd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/support/category/edit/:categoryId"
              component={SupportCategoryEdit}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/support/ticket"
              component={TicketList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/support/ticket/chat/:ticketId"
              component={TicketChat}
              layout={Admin}
              type={"private"}
            />

            {/* Suppot Ticket */}

            {/* Report */}
            <ConditionRoute
              path="/trade-history"
              component={TradeHistory}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/withdraw"
              component={WithdrawList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/fund-transfer-history"
              component={FundTansferHistory}
              layout={Admin}
              type={"private"}
            />
            {/* Report */}

            <ConditionRoute
              exact
              path="/Communitycategorylist"
              component={CommunitycategoryList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/Communityaddcategory"
              component={Communitycategoryadd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/Communitycategoryedit/:userId"
              component={CommunitycategoryEdit}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/CommunityUserList"
              component={CommunityUserList}
              layout={Admin}
              type={"private"}
            />
            {/*fiat*/}
            <ConditionRoute
              path="/fiatcurrency"
              component={Fiatlist}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/add-fiatcurrency"
              component={Fiatadd}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/updatefiatCurrency/:currencyId"
              component={Fiatedit}
              layout={Admin}
              type={"private"}
            />
            {/* Public Route */}

            {/* Public Route */}
            {/* <Route path="/login" component={LoginPage} /> */}
            <Redirect from="/" to="/login" />

            {/* <Route path="/admin" component={Admin} /> */}
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
