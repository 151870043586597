import React, { useEffect, useState } from "react";
import SpritzSDK from "@spritz-finance/widget";

let spritz = new SpritzSDK({
  integrationKey: "ak_MGIyMGFhNzctYWIyMy00MmEyLTk4MDUtY2NmYzg3N2M1NTAx", // required
  environment: "PRODUCTION", //STAGING,PRODUCTION // required,
  provider: window.ethereum.provider, // optional, an ethers provider. Will bridge your app's web3 provider into the Spritz app
  height: "650px", // optional
  width: "100%", //optional
});

export default function Widget() {
  useEffect(() => {
    spritz.init();

    // To get all the events
    spritz.on(spritz.ALL_EVENTS, (data) => {
      console.log(data, "-------21");
    });
  }, []);

  return (
    <div
      id="container"
      style={{ overflow: "scroll", "-webkit-overflow-scrolling": "touch" }}
    >
      <iframe width="4000" height="100%" src="">
        <></>
      </iframe>
    </div>
  );
}
