// import axios
import axios from 'axios';

// import lib
import config from '../lib/config';


console.log(config, 'url')

export const getAllExplore = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/adminapi/explore`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const addExplore = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/adminapi/explore`,
            data
        });
        return {
            status: "success",
            loading: true,
            message: respData.data.message,
            result: respData.data.result
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const deletexplore = async (id) => {
    try {
        let respData = await axios({
            'method': 'delete',
            'url': `${config.baseUrl}/adminapi/deletexplore/` + id,
        });

        return {
            status: "success",
            loading: false,
            messages: respData.data.message,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateExplore = async (reqData) => {
    try {
        console.log(reqData, 'dataaaaaaaaa')
        let respData = await axios({
            'method': 'put',
            'url': `${config.baseUrl}/adminapi/explore`,
            'data': reqData
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}



export const getSingleExplore = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/adminapi/getExploredata/` + id,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
