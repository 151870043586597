import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { logout } from "../actions/users";

const Logoutpage = () => {
    const history = useHistory();
    const logoutadmin = () => {
        localStorage.removeItem('admin_token');
        history.push('/login')
    }

    useEffect(() => {
        logoutadmin()
        //logout();
    }, [])

    return (<></>)
}
export default Logoutpage;
