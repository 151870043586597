import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import fileObjectUrl from "../../lib/fileObjectUrl";

// import action
import { addFiatCurrency } from "../../actions/currency";

// import lib
import { toastAlert } from "../../lib/toastAlert";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const initialFormValue = {
  type: "",
  currencyName: "",
  currencySymbol: "",
  withdrawFee: "",
  currencycode: "",
  decimals: "",
  minabi: "", // if token
  contractAddress: "", // if token
  bankName: "", // if fiat
  name: "", // if fiat
  accountNo: "", // if fiat
  routingNo: "", // if fiat
  currencyImage: "",
};

const useStyles = makeStyles(styles);

const Fiatadd = () => {
  const classes = useStyles();
  const history = useHistory();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const {
    type,
    currencycode,
    currencyName,
    currencySymbol,
    withdrawFee,
    minabi,
    decimals,
    contractAddress,
    bankName,
    name,
    accountNo,
    routingNo,
    currencyImage,
  } = formValue;

  // function

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  const handleFile = (event) => {
    const { name, files } = event.target;
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currencyImage) {
        if (currencyImage.size > 150000) {
          setValidateError({
            errors: { currencyImage: "Image size should be less than  150 Kb" },
          });
          toastAlert(
            "error",
            "Image size should be less than  150 Kb",
            "currencyUpdateModal"
          );
          return false;
        }
      }
      let reqData = new FormData();
      reqData.append("currencyName", currencyName);
      reqData.append("currencySymbol", currencySymbol);
      reqData.append("currencycode", currencycode);
      reqData.append("currencyImage", currencyImage);

      const { status, loading, messages, error } = await addFiatCurrency(reqData);
      if (status == "success") {
        toastAlert("success", messages, "addCurrency");
        setFormValue(initialFormValue);
        setValidateError({});
        history.push("/fiatcurrency");
      } else {
        setValidateError(error);
      }
    } catch (err) {
      console.log(err, "errror");
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>New Fiat Currency</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                 

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Name"
                      name="currencyName"
                      value={currencyName}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.currencyName && (
                      <span className={classes.textDanger}>
                        {validateError.currencyName}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Symbol"
                      name="currencySymbol"
                      value={currencySymbol}
                      onChange={handleChange}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.currencySymbol && (
                      <span className={classes.textDanger}>
                        {validateError.currencySymbol}
                      </span>
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Code"
                      onChange={handleChange}
                      value={currencycode}
                      name="currencycode"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    {validateError.currencycode && (
                      <span className={classes.textDanger}>
                        {validateError.currencycode}
                      </span>
                    )}
                  </GridItem>

                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Currency Image"
                      name="currencyImage"
                      onChange={handleFile}
                      type="file"
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    <img
                      src={fileObjectUrl(currencyImage)}
                      width="50"
                      height="50"
                    />

                    {validateError.currencyImage && (
                      <span className={classes.textDanger}>
                        {validateError.currencyImage}
                      </span>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  ADD
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Fiatadd;
