// import packages
import axios from 'axios';

// import lib
import config from './config';
import { getAuthToken } from './localStorage'

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common['Authorization'] = getAuthToken();

export default axios;